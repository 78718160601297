.pianokoulu {
    background-image: url('../images/taustakuva2.jpeg');
    /* background-color: black; */
    background-size: 100%;
    color: antiquewhite;
    font-size: 20px;
    width: auto;
    padding: 8vw;
    padding-top: 10vw;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    border-radius: 1.5vw;
}

.opac {
    background-color: rgba(36, 13, 13, 0.6);
    border-radius: 15px;
    padding-bottom: 4px;
    padding-bottom: 4px;
}


.notes {
    margin-bottom: 5vw;
}

.img2 img {
    max-width: 100%;
    width: 50%;
    border-radius: 15px;
    box-shadow: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

.bd1 {
    margin-top: 20vw;
}

.buttonDiv button {
    background-image: linear-gradient(to bottom right, #ffff3c, #f56416, #fa0202);
    color: black;
    font-weight: bold;
}

.hinnasto,
.lukukausi {
    padding: 4vw;
    padding-top: 10vw;
    text-align: left;
    margin-left: 10%;
}

.fa-music {
    color: orange;
    font-size: 5vw;
}

@media only screen and (max-width: 812px) {
    .pianokoulu {
        background-image: url('../images/taustakuvaPhone.jpeg');
        font-size: 14px;
        padding: 4vw;
        padding-top: 10vw;
    }

    .img2 img {
        width: 100%;
        margin-top: 15vw;
        margin-bottom: 10px;
    }

    .bd1 {
        margin-top: 10vw;
    }

    .opac {
        background-color: rgba(36, 13, 13, 0.6);
        border-radius: 15px;
        padding-bottom: 4px;
        padding-bottom: 4px;
    }

    .opac2 {
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: 20%;
        margin-right: 20%;
    }
}
