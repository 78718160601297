.contact {
    background-image: radial-gradient(rgb(235, 235, 22), rgb(223, 152, 21), rgb(161, 3, 3));
    font-size: 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 100px;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 1100px;
    padding-bottom: 50px;
    border-radius: 1.5vw;
}

.contact .typewriter {
    width: 100%;
    height: 4vw;
    font-size: 6vw;
    text-align: center;
    margin-top: 5vw;
}

.info {
    width: 40%;
    padding-left: 10%;
    text-align: center;
}

.map {
    width: 40%;
}

.contact iframe {
    border: black solid 2px;
    margin-top: 5vw;
    width: 100%;
    height: 400px;
}

@media only screen and (max-width: 811px) {
    .info {
        width: 100%;
        text-align: center;
        font-size: 20px;
        padding-left: 0;
    }

    .map {
        width: 90%;
        margin-left: 5%;
        margin-bottom: 30px;
    }
}

