.footer {
    background-image: linear-gradient( #030303, #372e2e);
    color: antiquewhite;
    font-size: 2vw;
    padding: 2vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10%;
}

.contactDiv {
    width: 30%;
    text-align: left;
}

@media only screen and (max-width: 811px) {
    .footer {
        font-size: 14px;
    }
    .contactDiv {
        width: 48%;
        text-align: left;
        padding-left: 10px;
    }
    .contactDiv2 {
        width: 48%;
        text-align: right;
        padding-right: 10px;
    }
    
}

@media only screen and (max-width: 524px) {
    .footer {
        font-size: 12px;
        padding-left: 5%;
        padding-right: 5%;
    }
    .contactDiv {
        padding-left: 5px;
    }
    .contactDiv2 {
        padding-right: 5px;
    }
}
