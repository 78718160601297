.popup {
    position: fixed;
    top: 0;
    left:10%;
    width: 80%;
    height: auto;
    background-image: linear-gradient( #030303, #372e2e);
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.popup-inner {
    position: relative;
    width: 100%;
}


.close-btn {
    height: 5vw;
    width: 5vw;
    position: absolute;
    top: 1vw;
    right: 1vw;
    border-radius: 3px;
    font-size: 3vw;
    text-align: center;
    box-shadow: none;
}

@media only screen and (max-width: 811px) {
    .popup {
        font-size: 14px;
    }
    .close-btn {
        height: 35px;
        width: 35px;
        position: absolute;
        top: 10px;
        right: 5px;
        border-radius: 3px;
        font-size: 20px;
        text-align: center;
    }
  }

