.slider {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    max-width: 100%;
    border-radius: 10px;
    border: none;
    box-shadow: none;
}

.right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: antiquewhite;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color:antiquewhite;
    z-index: 10;
    cursor: pointer;
    user-select: none;
}


