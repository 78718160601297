.musicDiv {
    background-image: none;
    background-color: black;
    min-height: 1200px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 50px;
    font-size: 20px;
}

/* lähteenä: 
    https://fossheim.io/writing/posts/css-text-gradient/ */
.gradient-text {
    background-image: radial-gradient(rgb(235, 235, 22), rgb(223, 152, 21), rgb(161, 3, 3));
    background-size:100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    text-shadow: 0px 0px #00000000;
}

.lesson {
    padding: 4vw;
}

h1 {
    text-align: center;
}

.pictures {
    margin-top: 3vw;
}

.pictures img {
    width: 32%;
    box-shadow: none;
}

.artist {
    margin-top: 5vw;
    display: flex;
    align-items: center;
}

.lesson,
.artist,
.example {
    margin-left: 10%;
    margin-right: 10%;
}


.image {
    width: 30%;
}

.image img {
    box-shadow: none;
}

.about {
    width: 70%;
}

@media only screen and (max-width: 811px) {
    .musicDiv {
        font-size: 14px;
    }

    .artist {
        flex-direction: column;
    }

    .image {
        width: 90%;
    }

    .musicDiv a {
        margin-left: 0;
    }

    .about {
        width: 100%;
    }

    .lesson,
    .artist,
    .example {
        margin-left: 7%;
        margin-right: 7%;
}
}

@media only screen and (max-width: 355px) {
    .pictures img {
        border: none;
    }
}